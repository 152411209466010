import React, { Component } from "react";
import { connect } from "react-redux";
import "./personalInfo.scss";
import NumberFormat from "react-number-format";
import util from "../../_helpers/util";

const step = 1 ;
const page = "personalInfo" ;
class PersonalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        position_applying_for:"",
        fname: "",
        sname: "",
        lname: "",
        mailingAddress1: "",
        mailingAddress2: "",
        city: "",
        state: "",
        zip: "",
        email: "",
        main_phone: "",
        secondary_phone: "",
        sourceOfKnowledge: "",
        employee_name: "",
        applyingCompany:"1",
        step: step
      }
    };
  }

  blur(event) {
    util.blur(event);
  }
  submitForm(event) {
    event.preventDefault();
    if (!util.forceVlidate(document.getElementById( page ))) {
      return;
    }
     if( "function" === typeof this.props.action ){
      //this.props.dispatch(apis.hasChanges(this.state.__changes))
      this.props.action({...this.state.form,step},step);
      this.setState({
        __changes : false
      })
     }
      
  }
  setValue(event) {
    util.setValue.call(this, event);
    if (util.isblurred(event.target)) {
      var valid = util.validate(event.target);
    }
  }
  componentDidMount() {
    if (this.props.api && this.props.api.aplicationDetail) {
      //console.log( this.props.api.aplicationDetail );
      var data = this.props.api.aplicationDetail,
        obj = Object.assign({}, this.state.form);
      this.setState({
        form: util._merge(obj, data, {
          position_applying_for:"position_applying_for",
          fname: "first_name",
          lname: "last_name",
          sname: "middle_initial",
          email: "email_address",
          zip: "zip_id",
          state: "state_id",
          city: "city_id",
          mailingAddress1: "mailing_address1",
          mailingAddress2: "mailing_address2",
          main_phone: "phone_number",
          sourceOfKnowledge: "source_of_knowledge_id",
          employee_name: "nvam_employee",
        })
      });
    }else{
      this.setState({
        form : Object.assign({},this.state.form,{email:localStorage.getItem("__userEmail")})
      })
    }
  }
  render() {
    var prefetchedData = this.props.api || {};
    return (
      <div className="aplcn-form aplcn-form-one">
        <form
          method="post"
          onSubmit={this.submitForm.bind(this)}
          id={page}
        >
          <div className="aplcn-form-section-head">
            <h4>How Did You Hear About Applying To About Trees?*</h4>
          </div>
          <div className="aplcn-form-row-main aplcn-has-inputs">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                <select
                  required
                  onBlur={this.blur.bind(this)}
                  onChange={this.setValue.bind(this)}
                  required
                  className=""
                  value={this.state.form.sourceOfKnowledge}
                  name="form.sourceOfKnowledge"
                >
                  <option value=""> --- Select Location --- </option>
                  {prefetchedData.SourceOfKnowledge &&
                    prefetchedData.SourceOfKnowledge.map((item, index) => {
                      return (
                        <option
                          value={item.id}
                          key={"sourceOfKnowledge_" + item.id}
                        >
                          {item.value}
                        </option>
                      );
                    })}
                </select>
              </div>
              {this.state.form.sourceOfKnowledge == "1" && (
                <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                  <input
                    type="text"
                    name="form.employee_name"
                    onBlur={this.blur.bind(this)}
                    onChange={this.setValue.bind(this)}
                    value={this.state.form.employee_name}
                    placeholder="Name of Employee*"
                    required
                  />
                </div>
              )}
            </div>
          </div>

          <div className="aplcn-form-section-head">
            <h4>What position are you applying for?</h4>
          </div>
          <div className="aplcn-form-row-main aplcn-has-inputs">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="Position"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.position_applying_for}
                  name="form.position_applying_for"
                  title="Please enter position you are applying for."
                />
              </div>
            </div>
          </div>

          <div className="aplcn-form-section-head">
            <h4>Full Legal Name*</h4>
          </div>
          <div className="aplcn-form-row-main aplcn-has-inputs">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="First Name*"
                  name="form.fname"
                  required
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.fname}
                  title="First name"
                />
              </div>
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="Middle Name"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.sname}
                  name="form.sname"
                  title="Middile Name"
                />
              </div>
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="Last Name*"
                  required
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.lname}
                  name="form.lname"
                  title="Last name"
                />
              </div>
            </div>
          </div>
          <div className="aplcn-form-section-head">
            <h4>Current Mailing Address*</h4>
          </div>
          <div className="aplcn-form-row-main aplcn-has-inputs">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="Address Line 1* "
                  required
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.mailingAddress1}
                  name="form.mailingAddress1"
                  title="Address 1"
                />
              </div>
              <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="Address Line 2"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.mailingAddress2}
                  name="form.mailingAddress2"
                  title="Address 2"
                />
              </div>
            </div>
          </div>
          <div className="aplcn-form-row-main aplcn-has-top-space aplcn-has-inputs">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="City*"
                  required
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.city}
                  name="form.city"
                  title="City"
                />
              </div>
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="State* "
                  required
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.state}
                  name="form.state"
                  title="State"
                />
              </div>
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="ZIP*"
                  required
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.zip}
                  name="form.zip"
                  title="Zip"
                />
              </div>
            </div>
          </div>
          <div className="aplcn-form-section-head">
            <h4>Contact Information*</h4>
          </div>
          <div className="aplcn-form-row-main  aplcn-has-inputs">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                {/* <input
                  type="email"
                  placeholder="Email Address*"
                  required
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.email}
                  name="form.email"
                  title="Email"
                /> */}
                <div className="aplcn-input-demo">{this.state.form.email}</div>
              </div>
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <NumberFormat
                  placeholder="Phone Number (Main Contact)*"
                  required
                  data-validation="phonenumber"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  name="form.main_phone"
                  value={this.state.form.main_phone}
                  format="(###) ###-####"
                  mask=""
                  title="Phone Number(Main Contact)"
                />
              </div>
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <NumberFormat
                  placeholder="Secondary Phone Number"
                  data-validation="phonenumber"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.secondary_phone}
                  name="form.secondary_phone"
                  format="(###) ###-####"
                  mask=""
                  title="Secondary Phone Number"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 aplcn-form-main-actionbar">
              <div className="aplcn-form-actionbar">
                <button onClick={(event)=>{
                  event.preventDefault();
                }} disabled={true} type="button" className="aplcn-prev-button">
                  Previous
                </button>
                <button type="submit" className="aplcn-next-btn">
                  Next
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return state;
}
const connectedApp = connect(mapStateToProps)(PersonalInfo);
export { connectedApp as PersonalInfo };
