import React, { Component } from "react";
import { connect } from "react-redux";
import "./leagalRemarks.scss";
import util from "../../_helpers/util";
import DatePicker from "react-datepicker";

const step = 6;
const page = "legalRemarks";
class LeagalRemarks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newApplication:true,
      form: {
        Veteran: "no",
        Race: "",
        gender: "",
        FullName: "",
        txtDate: "",
        agreement: [],
        step: step,
        applyingCompany:"1",
      }
    };
  }

  componentDidMount() {
    if (this.props.api && this.props.api.aplicationDetail) {
      var data = this.props.api.aplicationDetail,
        obj = Object.assign({}, this.state.form);
      this.setState({
        form: util._merge(obj, data, {}, res => {
          return this.generateLicenceDetails(res);
        })
      });
    }
  }

  blur(event) {
    util.blur(event);
  }
  componentDidMount() {}

  setValue(event) {
    util.setValue.call(this, event);
    if (util.isblurred(event.target)) {
      var valid = util.validate(event.target);
    }
  }
  handleDateChange(name, date) {
    util.setValue.call(this, null, {
      value: date,
      name: name
    });
  }

  submitForm(event) {
    event.preventDefault();
    if (!util.forceVlidate(document.getElementById(page))) {
      return;
    }
    "function" === typeof this.props.action &&
      this.props.action(
        {...this.state.form,step} , step
      );
  }

  componentDidMount() {
    let newApplication = false;

    if (this.props.api && this.props.api.aplicationDetail) {
      var data = this.props.api.aplicationDetail,
        obj = Object.assign({}, this.state.form);

      if(
      !data.date ||
      data.date == "" || 
      new Date(data.date).getFullYear() < new Date().getFullYear())
      {
        newApplication = true;
      }
      else
      {
        newApplication = false;
      }
    
      let form = util._merge(obj, data, {
        Veteran: "veteran",
        FullName: "full_name",
        Race: "race_id",
        txtDate: {
          target: "date",
          filter: "date"
        }
      });
        
      if(form.agreement && typeof form.agreement === 'string')
      {
        form.agreement = form.agreement.toLowerCase();
      }

      this.setState({form});
    }
  }

  render() {
    var prefetchedData = this.props.api || {};
    return (
      <div className="aplcn-form aplcn-form-two">
        <form method="post" onSubmit={this.submitForm.bind(this)} id={page}>
          <div className="aplcn-form-section-head">
            <h4>Legal And Final Remarks</h4>
          </div>
          <div className="aplcn-form-row-main aplcn-has-inputs">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-12 aplcn-inp-wrap">
                <p className="aplcn-desc aplcn-txt-big">
                  <i className="fa fa-info-circle" aria-hidden="true" /> &nbsp;
                  About Trees is an equal opportunity employer. All
                  candidates will be considered for employment without regard to
                  race, color, sex, religion, national origin and age.
                </p>
                <p className="aplcn-desc aplcn-txt-big">
                  About Trees is a Federal Contractor and is required to meet
                  certain guidelines for our Affirmative Action Plan; therefore,
                  we request that you submit the following information. This
                  information is voluntary and will not affect your hiring
                  eligibility. This information will remain confidential and
                  hiring supervisors will not see this information.
                </p>
              </div>
            </div>
          </div>

          <div className="aplcn-form-row-main  aplcn-has-inputs">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                <select
                  name="form.gender"
                  value={this.state.form.gender}
                  required
                  onBlur={this.blur.bind(this)}
                  onChange={this.setValue.bind(this)}
                  required
                >
                  <option value="">--- Select Gender ---</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>

              <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                <select
                  required
                  onBlur={this.blur.bind(this)}
                  onChange={this.setValue.bind(this)}
                  required
                  className=""
                  name="form.Race"
                  value={this.state.form.Race}
                >
                  <option value="">--- Select Race ---</option>
                  {prefetchedData.Race &&
                    prefetchedData.Race.map((item, index) => {
                      return (
                        <option value={item.id} key={"Race" + item.id}>
                          {item.value}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>

          <div className="aplcn-form-section-head">
            <h5 className="aplcn-is-graduate">Are you a Veteran</h5>
          </div>

          <div className="aplcn-form-row-main">
            <ul className="aplcn-checklist inline-radios">
              <li>
                <input
                  value="yes"
                  type="radio"
                  id="Veteran1"
                  onChange={this.setValue.bind(this)}
                  name="form.Veteran"
                  checked={this.state.form.Veteran === "yes"}
                />
                <label htmlFor="Veteran1">
                  <span className="aplcn-check-label">Yes</span>
                </label>
              </li>
              <li>
                <input
                  value="no"
                  type="radio"
                  id="Veteran23"
                  onChange={this.setValue.bind(this)}
                  name="form.Veteran"
                  checked={this.state.form.Veteran !== "yes"}
                />
                <label htmlFor="Veteran23">
                  <span className="aplcn-check-label">No</span>
                </label>
              </li>
            </ul>
          </div>

          <div className="aplcn-form-section-head">
            <div className="aplcn-tearms-and-conditions">
              <p>
                Submission of false information is grounds for employment and
                candidacy termination.
              </p>
              <p>
                I hereby authorize About Trees to
                thoroughly investigate my references, work record, education and
                other matters related to my suitability for employment
                (excluding criminal background information) unless otherwise
                specified above. I further, authorize the references I have
                listed to disclose to the company any and all letters, reports
                and other information related to my work records, without giving
                me prior notice of such disclosure. In addition, I hereby
                release the Company, my former employers and all other persons,
                corporations, partnerships and associations from any and all
                claims, demands or liabilities arising out of or in any way
                related to such investigation or disclosure.
              </p>
              <p>
                I verify that all information provided on this application is in
                fact true to the best of my knowledge and understand that if
                information is not true, my candidacy and/or employment will be
                terminated. By filling out your name and dating below is a legal
                agreement between you (the applicant) and About Trees.
              </p>

              <div className="aplcn-form-row-main">
                <ul className="aplcn-checklist inline-radios">
                  <li className="aplcn-green-check">
                    <input
                      value="yes"
                      type="checkbox"
                      id="agreement_12"
                      onChange={this.setValue.bind(this)}
                      name="form.agreement"
                      required
                      checked={this.state.form.agreement === "yes"}
                      className="aplcn-visible-check"
                      checked={util.defaultChecked(
                        "yes",
                        this.state.form.agreement
                      )}
                    />
                    <label htmlFor="agreement_12">
                      <span className="aplcn-check-label">
                        I agree to the above statement
                      </span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="aplcn-form-row-main  aplcn-has-inputs">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="Type legal full name"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.FullName}
                  name="form.FullName"
                  required
                />
              </div>
              <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                <DatePicker
                  selected={this.state.form.txtDate}
                  showYearDropdown
                  onChange={this.handleDateChange.bind(this, "form.txtDate")}
                  placeholderText="Todays Date"
                  dateFormat={util._datePickerFormat()}
                  required
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 aplcn-form-main-actionbar">
              <div className="aplcn-form-actionbar">
                <button onClick={this.back.bind(this)} type="button"  disabled="" className="aplcn-prev-button">
                  Previous
                </button>
                <button type="submit" className="aplcn-next-btn">
                  {this.state.newApplication?'Save':'Update'}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  back(event){
    event.preventDefault(); 
    this.props.back( step );
  }
}
function mapStateToProps(state) {
  return state;
}
const connectedApp = connect(mapStateToProps)(LeagalRemarks);
export { connectedApp as LeagalRemarks };
