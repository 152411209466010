import React, { Component } from "react";
import { connect } from "react-redux";
import { FormHeader } from "../formHeader/formHeader";
import "./aplicationForm.scss";
import { PersonalInfo } from "../personalInfo/personalInfo";
import { Preferences } from "../preferences/preferences";
import { Qualification } from "../qualification/qualification";
import { Experience } from "../experience/experience";
import { DriverQualification } from "../driverQualification/driverQualification";
import { LeagalRemarks } from "../legalRemarks/legalRemarks";
import { apis } from "../../actions";
import util from "../../_helpers/util";
import { confirmAlert } from "react-confirm-alert";
class AplicationForm extends Component {
  constructor(props) {
    super(props);
    this.dataLoaded = 0;
    this.state = {
      currentSubmit: null,
      form: {},
      aplicationDetail: {},
      dataLoaded: 0,
      formLoading: false,
      tabs: [
        "personal-info",
        "preferences",
        "qualification",
        "experience",
        "driver-qualification",
        "legal-remarks"
      ]
    };
  }
  componentDidMount() {
    // AT:Site ID #1
    this.props.dispatch(apis.getaplications(1));
    this.props.dispatch(apis.getListAll());
    this.loadData();
  }
  loadData() {
    var hash = this.props.match.params.hash || null;
    if (!hash || hash === "new-application") {
      this.dataLoaded++;
      this.setState({
        dataLoaded: this.dataLoaded
      });
      return;
    }
    hash !== "new-application" && this.props.dispatch(apis.getForm(hash || !1));
  }

  prev(index) {
    if (index - 1 < 0) return;
    var path = this.state.tabs[index - 2];
    this.props.history.push(
      "/" + this.props.match.params.hash + "/" + path
    );
  }
  componentWillReceiveProps(nextProps) {  
    if (nextProps.api && nextProps.api.aplicationDetail ) { 
      this.setState({
        aplicationDetail : nextProps.api.aplicationDetail ,
        dataLoaded : ++this.state.dataLoaded
      });
    }
    if (
      nextProps.api &&
      nextProps.api.AnticipatedLocation &&
      this.state.dataLoaded < 2
    ) {
    
        this.setState({
          dataLoaded: ++this.state.dataLoaded
        });
      }
    

    if (nextProps.api && nextProps.api.aplications) {
      if (
        nextProps.api.aplications.data &&
        nextProps.api.aplications.data.length &&
        this.props.match.params.hash === "new-application"
      ) {
        this.props.history.push("/applications");
        return;
      }
    }

    if (nextProps.api.formLoading && util.isObject(nextProps.api.formLoading)) {
      sessionStorage.removeItem("tabHasChanges");
      var message = util.parseMessage(nextProps.api.formLoading.message || "");
      if (message && !nextProps.api.formLoading.success) {
        confirmAlert({
          title: "Error Saving Data",
          message: message,
          buttons: [
            {
              label: "Close",
              onClick: () => {
                // this.props.dispatch(apis.reset());
              }
            }
          ]
        });
      } else {
        if (this.state.currentSubmit === 6) {
          confirmAlert({
            title: "Saved",
            message: message,
            buttons: [
              {
                label: "Close",
                onClick: () => {
                  this.props.history.push("/applications");
                }
              }
            ]
          });
        }
      }

      this.props.dispatch(apis.reset());
      this.loadData();
      this.setState(
        {
          formLoading: false
        },
        () => {}
      );
      if (
        nextProps.api.formLoading.success &&
        this.props.match.params.hash === "new-application"
      ) {
        this.props.history.push(
          "/" + nextProps.api.formLoading.data + "/personal-info"
        );
      }
      if (
        nextProps.api.formLoading &&
        nextProps.api.formLoading.success &&
        this.state.currentSubmit !== 6
      ) {
        this.props.history.push(
          "/" +
            nextProps.api.formLoading.data +
            "/" +
            this.state.tabs[this.state.currentSubmit]
        );
        this.setState({
          currentSubmit: this.state.currentSubmit + 1
        });
      }
    }
  } 
  formReady(){
    const { tab } = this.props;
    if( this.props.match.params.hash === "new-application" )
      return true ; 
      //console.log( Object.keys(this.state.aplicationDetail).length , "#############" );
      if( Object.keys(this.state.aplicationDetail).length > 0 )
        return true  ;
        return false ;
  }
  componentDidUpdate = () => {
    window.scrollTo(0, 0);
  };
  saveForm(data, step) {
    var obj = Object.assign(this.state.form, data),
      hash = this.props.match.params.hash || false;
    if (!(data && data.qualification_file)) {
      delete obj.qualification_file
    }
    if (hash && hash !== "new-application") {
      obj["hash"] = this.props.match.params.hash;
    }
    this.props.dispatch(apis.saveForm(obj));
    this.setState({
      formLoading: true,
      currentSubmit: step || 1
    });
  }
  logout() {
    // localStorage.clear();
    // this.props.history.push("/");
  }
  render() {
    const { tab } = this.props;
    let nextTab = tab == 6 ? parseInt(tab) : parseInt(tab) + 1;
    let prevTab = tab == 1 ? parseInt(tab) : parseInt(tab) - 1;
    return (
      <div className="aplcn-body">
        {this.state.formLoading && <div className="form-data-loader" />}

        <div className="container">
          <FormHeader
            {...this.props}
            logout={this.logout.bind(this)}
            active={this.props.tab}
          />
          
          { this.state.dataLoaded < 2 && (
            <div className="aplcn-form-data-prefetch">Loading Form..</div>
          )}
          { this.state.dataLoaded >= 2 && (
            <div className="row">
              <div className="col-xs-12">
                {tab == 1 && (
                  <PersonalInfo
                    back={this.prev.bind(this)}
                    action={this.saveForm.bind(this)}
                  />
                )}
                {tab == 2 && (
                  <Preferences
                    back={this.prev.bind(this)}
                    action={this.saveForm.bind(this)}
                  />
                )}
                {tab == 3 && (
                  <Qualification
                    back={this.prev.bind(this)}
                    action={this.saveForm.bind(this)}
                  />
                )}
                {tab == 4 && (
                  <Experience
                    back={this.prev.bind(this)}
                    action={this.saveForm.bind(this)}
                  />
                )}
                {tab == 5 && (
                  <DriverQualification
                    back={this.prev.bind(this)}
                    action={this.saveForm.bind(this)}
                  />
                )}
                {tab == 6 && (
                  <LeagalRemarks
                    back={this.prev.bind(this)}
                    action={this.saveForm.bind(this)}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return state;
}
const connectedApp = connect(mapStateToProps)(AplicationForm);
export { connectedApp as AplicationForm };
