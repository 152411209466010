import React, { Component } from "react";
import { connect } from "react-redux";
import linkedin from "../../images/linked-in-icon.svg";
import facebook from "../../images/facebook-icon.svg";
class Footer extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="outer_application_form">
        <footer>
          {/* ----- New Footer ----- */}
          <div className="nvam-footer-wrapper">
            <div className="wrapper">
              <div className="row d-flex flex-center">
                <div className="col-md-8 d-none">
                  <h4>About Trees</h4>
                  <div className="row two-col-row">
                    <div className="col-md-6">
                      <h5>Chico Facility</h5>
                      <p>2 Crusader Ct Ste 2, Ste 2 Chico, CA 95973</p>

                      <p>
                        Phone:{" "}
                        <a href="tel:530-343-4533" class="tel">
                          530-343-4533{" "}
                        </a>
                        (Hours: Mon-Fri, 9am-4pm)
                      </p>

                      <p>
                        Fax:{" "}
                        <a href="tel:530-343-4533" class="tel">
                          530-343-4533
                        </a>
                      </p>
                    </div>

                    <div className="col-md-6">
                      <h5>Redding Facility</h5>
                      <p>6000 Bellevue Lane Anderson, CA 96007</p>

                      <p>
                        Phone:{" "}
                        <a href="tel:530-343-4533" class="tel">
                          530-246-2477{" "}
                        </a>
                        <span> (Hours: Mon-Fri, 9am-4pm)</span>
                      </p>
                    </div>
                  </div>

                  <div className="nvam-footer-social-wrap">
                    <a href="#" className="nvam-social-item" target="_blank">
                      <img src={linkedin} alt="" />
                    </a>
                    <a href="#" className="nvam-social-item" target="_blank">
                      <img src={facebook} alt="" />
                    </a>
                  </div>
                </div>

                <div className="">
                  <h4>About Trees</h4>
                  <div className="row">
                    <div className="col-md-12">
                      {/* <h5>Chico Facility</h5> */}
                      <p>
                        2 Crusader Ct Ste 2,
                        <br /> Chico, CA 95973
                      </p>

                      <p>
                        Phone:{" "}
                        <a href="tel:530-343-4533" class="tel">
                          530-343-4533{" "}
                        </a>
                        <span> (Hours: Mon-Fri, 9am-4pm) </span>
                      </p>

                      <p>
                        Fax:{" "}
                        <a href="tel:530-267-8855" class="tel">
                          530-267-8855
                        </a>
                      </p>
                    </div>
                  </div>

                  <div className="nvam-footer-social-wrap">
                    {/* <a href="#" className="nvam-social-item" target="_blank">
                      <img src={linkedin} alt="" />
                    </a> */}
                    <a href="https://www.facebook.com/AboutTrees/" className="nvam-social-item" target="_blank">
                      <img src={facebook} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <p className="nvam-foot-copyright">Copyright © 2021 About Trees and its licensors. All rights reserved.</p>
          </div>
        </footer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}
const connectedApp = connect(mapStateToProps)(Footer);
export { connectedApp as Footer };
