import React, { Component } from "react";
import { connect } from "react-redux";
import { config } from "../../_config/_config";
import logo from "../../images/about_trees_logo.png";
import tel_icon from "../../images/tel-icon.svg";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.setNavExpanded = this.setNavExpanded.bind(this);
  }
  setNavExpanded(expanded) {
    this.setState({ navExpanded: expanded });
  }
  render() {
    return (
      <div className="outer_application_form">
        <header id="header">
          {/* ----- New Header ------ */}
          <section className="nvam-header-wrapper">
            <div className="wrapper">
              <div className="nvam-brand-wrapper">
                <a href={config.appUrl} title="About Trees">
                  <img src={logo} alt="About Trees logo" />
                </a>
              </div>

              <div className="nvam-right-side">
                <a href="tel:530-343-4533" className="tel">
                  <img className="icon" src={tel_icon} alt="" />
                  (530) 343-4533
                </a>
                <a href={config.baseUrl + "login"} className="nvam-btn">
                  EMPLOYEE LOGIN
                </a>
              </div>
            </div>
          </section>
        </header>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}
const connectedApp = connect(mapStateToProps)(Header);
export { connectedApp as Header };
