import React, { Component } from "react";
import { connect } from "react-redux";
import "./driverQualification.scss";
import util from "../../_helpers/util";
import DatePicker from "react-datepicker";

const step = 5;
const page = "driverQualification";
class DriverQualification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        DeniedLicense: "no",
        Question3: "yes",
        //Question2: "yes", // Removed for NVAM clone
        //Question1: "yes", // Removed for NVAM clone
        licenseState: "",
        licenseNumber: "",
        licenseExpDate: "",
        licenseClass: "",
        licenseEndorsement: "",
        LicenseRevoked: "no",
        step: step,
        _licence: "",
        applyingCompany:"1",
      },
      errors:{}
    };
  }

  submitForm(event) {

    event.preventDefault();

    if (!util.forceVlidate(document.getElementById(page))) {
      return;
    }

    let { /*Question1, Question2,*/ Question3 } = this.state.form // Removed for NVAM clone
    let questions = { /*Question1, Question2,*/ Question3 }; // Removed for NVAM clone

    let err = false;

    let errors = {};

    Object.keys(questions).forEach(key => {
      if(questions[key] == "no"){
        errors[`form.${key}`] = "Unfortunately by selecting no, you do not fulfill our requirements for this job."
        err = true
      }
    })

    if(err){
      this.setState({
        errors:{
          ...this.state.errors,
          ...errors
        }
      });
      
      return;
    }

    "function" === typeof this.props.action &&
      this.props.action({...this.state.form,step},step);
  }

  componentDidMount() {
    if (this.props.api && this.props.api.aplicationDetail) {
      var data = this.props.api.aplicationDetail,
        obj = Object.assign({}, this.state.form);
      this.setState({
        form: util._merge(
          obj,
          data,
          {
            // Question1: "question1", // Removed for NVAM clone
            // Question2: "question2", // Removed for NVAM clone
            Question3: "question3",
            DeniedLicense: "denied_license",
            LicenseRevoked: "license_revoked",
            _licence: "license"
          },
          res => {
            return this.generateLicenceDetails(res);
          }
        )
      });
    }
  }
  generateLicenceDetails(res) {
    var res = res || {};
    res = util.extractObjectAndMerge(
      res,
      {
        class: { name: "licenseClass" },
        state: { name: "licenseState" },
        no: { name: "licenseNumber" },
        endorsement: { name: "licenseEndorsement" },
        exp_date: { name: "licenseExpDate" },
        state_id: { name: "licenseState" }
      },
      ["_licence"]
    );
    return res;
  }

  blur(event) {
    util.blur(event);
  }

  setValue(event) {
    util.setValue.call(this, event);
    if (util.isblurred(event.target)) {
      var valid = util.validate(event.target);
    }
    this.setState({errors:{
      ...this.state.errors,
      [event.target.name]:""
    }})
  }
  handleDateChange(name, date) {
    util.setValue.call(this, null, {
      value: date,
      name: name
    });
  }
  render() {
    var prefetchedData = this.props.api || {};
    return (
      <div className="aplcn-form aplcn-form-two">
        <form method="post" onSubmit={this.submitForm.bind(this)} id={page}>
          <div className="aplcn-form-section-head">
            <h4>Driver Qualifications</h4>
          </div>
          <div className="aplcn-form-row-main aplcn-has-inputs">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-12 aplcn-inp-wrap">
                <p className="aplcn-desc">
                  If you would like to be considered for a driving position
                  during your employment and you have a valid driver's license.
                  You need to complete the section below to be trained in and
                  operate a commercial vehicle.
                </p>

                <p className="aplcn-desc aplcn-txt-big">
                  <i className="fa fa-info-circle" aria-hidden="true" /> If you
                  do not have a valid or current driver's license you can skip
                  this section.
                </p>
              </div>
            </div>
          </div>
          <div className="aplcn-form-section-head">
            <h4>Driver License(s) past 3 years:</h4>
          </div>

          <div className="aplcn-form-row-main  aplcn-has-inputs">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="State"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.licenseState}
                  name="form.licenseState"
                />
              </div>
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="License#"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.licenseNumber}
                  name="form.licenseNumber"
                />
              </div>
              <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                <DatePicker
                  selected={this.state.form.licenseExpDate}
                  showYearDropdown
                  onChange={this.handleDateChange.bind(
                    this,
                    "form.licenseExpDate"
                  )}
                  placeholderText="Exp Date"
                  dateFormat={util._datePickerFormat()}
                />
              </div>
            </div>
          </div>

          <div className="aplcn-form-row-main  aplcn-has-inputs aplcn-has-top-space">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="Class A,B,C"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.licenseClass}
                  name="form.licenseClass"
                />
              </div>
              <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                <input
                  type="text"
                  placeholder="Endorsements"
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.licenseEndorsement}
                  name="form.licenseEndorsement"
                />
              </div>
            </div>
          </div>

          <div className="aplcn-form-section-head">
            <h5 className="aplcn-is-graduate">
              Have you ever been denied a license, permit or privilege to
              operate a motor vehicle?
            </h5>
          </div>
          <div className="aplcn-form-row-main">
            <ul className="aplcn-checklist inline-radios">
              <li>
                <input
                  value="yes"
                  type="radio"
                  id="DeniedLicense1"
                  onChange={this.setValue.bind(this)}
                  name="form.DeniedLicense"
                  checked={this.state.form.DeniedLicense === "yes"}
                />
                <label htmlFor="DeniedLicense1">
                  <span className="aplcn-check-label">Yes</span>
                </label>
              </li>
              <li>
                <input
                  value="no"
                  type="radio"
                  id="DeniedLicense2"
                  onChange={this.setValue.bind(this)}
                  name="form.DeniedLicense"
                  checked={this.state.form.DeniedLicense !== "yes"}
                />
                <label htmlFor="DeniedLicense2">
                  <span className="aplcn-check-label">No</span>
                </label>
              </li>
            </ul>
          </div>

          <div className="aplcn-form-section-head">
            <h5 className="aplcn-is-graduate">
              Has any license, permit or privilege ever been revoked?
            </h5>
          </div>
          <div className="aplcn-form-row-main">
            <ul className="aplcn-checklist inline-radios">
              <li>
                <input
                  value="yes"
                  type="radio"
                  id="LicenseRevoked1"
                  onChange={this.setValue.bind(this)}
                  name="form.LicenseRevoked"
                  checked={this.state.form.LicenseRevoked === "yes"}
                />
                <label htmlFor="LicenseRevoked1">
                  <span className="aplcn-check-label">Yes</span>
                </label>
              </li>
              <li>
                <input
                  value="no"
                  type="radio"
                  id="LicenseRevoked2"
                  onChange={this.setValue.bind(this)}
                  name="form.LicenseRevoked"
                  checked={this.state.form.LicenseRevoked !== "yes"}
                />
                <label htmlFor="LicenseRevoked2">
                  <span className="aplcn-check-label">No</span>
                </label>
              </li>
            </ul>
          </div>
          {this.state.form.LicenseRevoked === "yes" && (
            <div className="aplcn-form-row-main">
              <input
                type="text"
                placeholder="If Yes, please explain…"
                name="form.ReasonLicenseRevoked"
                onChange={this.setValue.bind(this)}
                onBlur={this.blur.bind(this)}
                value={this.state.form.ReasonLicenseRevoked}
              />
            </div>
          )}
          <div className="aplcn-form-section-head">
            <h4>Please Answer The Following Question</h4>
          </div>

          {/* <div className="aplcn-form-row-main aplcn-has-inputs"> // Removed for NVAM clone
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-12 aplcn-inp-wrap">
                <p className="aplcn-desc">
                  Are you willing to be on call 24 hours a day, 7 days a week,
                  work long shifts, go weeks without a day off, endure adverse
                  weather conditions, perform strenuous and prolonged physical
                  labor and go extended periods without a bed, shower or phone?*
                </p>
              </div>
            </div>
          </div>

          <div className="aplcn-form-row-main">
            <ul className="aplcn-checklist inline-radios">
              <li>
                <input
                  value="yes"
                  type="radio"
                  id="Question1"
                  onChange={this.setValue.bind(this)}
                  name="form.Question1"
                  checked={this.state.form.Question1 === "yes"}
                />
                <label htmlFor="Question1">
                  <span className="aplcn-check-label">Yes</span>
                </label>
              </li>
              <li>
                <input
                  value="no"
                  type="radio"
                  id="Question12"
                  onChange={this.setValue.bind(this)}
                  name="form.Question1"
                  checked={this.state.form.Question1 !== "yes"}
                />
                <label htmlFor="Question12">
                  <span className="aplcn-check-label">No</span>
                </label>
              </li>
            </ul>
            <div className="error-text">{ this.state.errors[`form.Question1`]}</div>
          </div>

          <div className="aplcn-form-row-main aplcn-has-inputs">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-12 aplcn-inp-wrap">
                <p className="aplcn-desc">
                  
                </p>
              </div>
            </div>
          </div>

          <div className="aplcn-form-row-main">
            <ul className="aplcn-checklist inline-radios">
              <li>
                <input
                  value="yes"
                  type="radio"
                  id="Question122"
                  onChange={this.setValue.bind(this)}
                  name="form.Question2"
                  checked={this.state.form.Question2 === "yes"}
                />
                <label htmlFor="Question122">
                  <span className="aplcn-check-label">Yes</span>
                </label>
              </li>
              <li>
                <input
                  value="no"
                  type="radio"
                  id="Question166"
                  onChange={this.setValue.bind(this)}
                  name="form.Question2"
                  checked={this.state.form.Question2 !== "yes"}
                />
                <label htmlFor="Question166">
                  <span className="aplcn-check-label">No</span>
                </label>
              </li>
            </ul>
            <div className="error-text">{ this.state.errors[`form.Question2`]}</div>
          </div> */}

          <div className="aplcn-form-row-main aplcn-has-inputs">
            <div className="row aplcn-row">
              <div className="col-sm-12 col-md-12 aplcn-inp-wrap">
                <p className="aplcn-desc">
                  NVAM is a drug free and smoke-free work
                  place. If hired, will you comply to our company drug and
                  smoking policies?*
                </p>
              </div>
            </div>
          </div>

          <div className="aplcn-form-row-main">
            <ul className="aplcn-checklist inline-radios">
              <li>
                <input
                  value="yes"
                  type="radio"
                  id="Question19032"
                  onChange={this.setValue.bind(this)}
                  name="form.Question3"
                  checked={this.state.form.Question3 === "yes"}
                />
                <label htmlFor="Question19032">
                  <span className="aplcn-check-label">Yes</span>
                </label>
              </li>
              <li>
                <input
                  value="no"
                  type="radio"
                  id="Question344"
                  onChange={this.setValue.bind(this)}
                  name="form.Question3"
                  checked={this.state.form.Question3 !== "yes"}
                />
                <label htmlFor="Question344">
                  <span className="aplcn-check-label">No</span>
                </label>
                </li>
            </ul>
            <div className="error-text">{ this.state.errors[`form.Question3`]}</div>
          </div>

          <div className="row">
            <div className="col-sm-12 aplcn-form-main-actionbar">
              <div className="aplcn-form-actionbar">
                <button onClick={this.back.bind(this)} type="button"  disabled="" className="aplcn-prev-button">
                  Previous
                </button>
                <button type="submit" className="aplcn-next-btn">
                  Next
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  back(event){
    event.preventDefault();
    this.props.back( step );
  }
}
function mapStateToProps(state) {
  return state;
}
const connectedApp = connect(mapStateToProps)(DriverQualification);
export { connectedApp as DriverQualification };
