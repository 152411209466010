import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../actions";
import util from "../../_helpers/util";
import { confirmAlert } from "react-confirm-alert";
import "./login.scss";
import { Redirect, Link } from "react-router-dom";
class Login extends Component {
  constructor(props) {
    super(props);
    var tabid = localStorage.getItem("__loginTab")
      ? parseInt(localStorage.getItem("__loginTab"))
      : 0;
    this.state = {
      tab: isNaN(tabid) ? 0 : tabid,
      loginSuccess: util.isLoggedIn(),
      _login: {
        email: "",
        password: "",
        auth_type: "password",
        type: "login",
        site_id:"1"
      },
      _register: {
        email: "",
        type: "register",
        password: "",
        password_confirmation: "",
        auth_type: "password",
        site_id:"1"
      },
    };
    this.switchTab = this.switchTab.bind(this);
  }
  
  handleLoginResponse(data, action) {
    this.setState({
      loginProgress: false
    });
    var obj = data || {};
    if (obj.error) {
      /* show alert if login fails */
      const hasReapplyPrivilege = obj?.error?.confirm_reapply
      let confirmAlertButtons = [
        {
          label: "Close",
          onClick: () => { }
        },
      ]
      if (hasReapplyPrivilege) {
        confirmAlertButtons.push({
          label: "Continue",
          onClick: () => {
            let _register = { ...this.state._register }
            _register.confirm_reapply = hasReapplyPrivilege;
            this.setState({ _register }, () => {
              this.login("register")
            })
          }
        })
      }
      confirmAlert({
        title: "Login Failed",
        message: util.parseMessage(obj.error),
        buttons: confirmAlertButtons
      });
    }
    if (obj.success) {
      var tocken = obj.success.token;
      var email = obj.success.email;

      /*
      Save users email to localstorage
      */
      if (!email) {
        localStorage.setItem(
          "__userEmail",
          action === "REGISTER"
            ? this.state._register.email
            : action === "LOGIN"
              ? this.state._login.email
              : ""
        );
      } else {
        localStorage.setItem("__userEmail", email);
      }

      /* updating users auth tocken */
      util.updateUserTocken.call(this, tocken, action);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      var auth = nextProps.user.auth || {};
      this.handleLoginResponse(auth, nextProps.user.action);
    }
  }
  switchTab = (event, tabid) => {
    /*
    Switching between tabs
    */
    event.preventDefault();
    typeof tabid !== undefined &&
      this.setState(
        {
          tab: tabid
        },
        () => {
          localStorage.setItem("__loginTab", tabid);
        }
      );
  }

  setValue(event) {
    var target = event.target;
    util.setValue.call(this, event);
    if (util.isblurred(target)) {
      var valid = util.validate(target);
    }
  }
  blur(event) {
    util.blur(event);
  }

  handleSocialLogin(user) {
    if (!user || !user._token) return;
    var tocken = user._token,
      acces = tocken.accessToken || "";
    if (!acces) return;
    this.props.dispatch(
      userActions.login({
        token: acces,
        auth_type: user._provider,
        type: "LOGIN"
      })
    );
  }
  login(type, e) {
    e?.preventDefault();
    if (this.state.loginProgress) {
      return;
    }
    if (!util.forceVlidate(document.getElementById(type))) {
      return;
    }
    this.setState({
      loginProgress: true
    });
    this.props.dispatch(
      userActions.login(
        type === "register" ? this.state._register : this.state._login
      )
    );
  }

  render() {
    if (this.state.loginSuccess && this.state.loginSuccess === "REGISTER") {
      return <Redirect to="/new-application/personal-info" />;
    }
    if (this.state.loginSuccess && this.state.loginSuccess === "LOGIN") {
      return <Redirect to="/applications" />;
    }
    if (util.isLoggedIn()) {
      return <Redirect to="/applications" />;
    }

    return (
      <div className="aplcn-body">
        <div className="container">
          <div className="row">
            <div className="col-12 aplcn-login-box-wrapper">
              <div className="aplcn-login-box-inner">
              <h1 className="nvam-title">Employment Application</h1>
                <div className="aplcn-header-tabs">
                  <div
                    className={
                      "aplcn-header-tab " +
                      (this.state.tab == 0 ? "aplcn-active-tab" : "")
                    }
                  >
                    <a
                      data-tab="0"
                      href="#"
                      onClick={event => this.switchTab(event, 0)}
                    >
                      New Application
                    </a>
                  </div>
                  <div
                    className={
                      "aplcn-header-tab " +
                      (this.state.tab == 1 ? "aplcn-active-tab" : "")
                    }
                  >
                    <a
                      data-tab="1"
                      href="#"
                      onClick={event => this.switchTab(event, 1)}
                    >
                      Track Application status
                    </a>
                  </div>
                </div>
                <div
                  className="aplcn-tabs-container"
                  data-active-tab={this.state.tab}
                >
                  <div className="aplcn-tabs-tab" data-tab-id="0">
                    <div className="aplcn-tab-form-wrapper">
                      <form
                        action=""
                        onSubmit={this.login.bind(this, "register")}
                        id="register"
                      >
                        <div className="aplcn-form-row">
                          <div className="aplcn-label-wrapper">
                            <label htmlFor="email">Email ID</label>
                          </div>
                          <div className="aplc-input-wrapper">
                            <input
                              spellCheck="false"
                              type="email"
                              name="_register.email"
                              id="email"
                              placeholder="Email ID"
                              required="required"
                              data-validation="email"
                              value={this.state._register.email}
                              onChange={this.setValue.bind(this)}
                              onBlur={this.blur.bind(this)}
                              title="Email"
                            />
                          </div>
                        </div>
                        <div className="aplcn-form-row">
                          <div className="aplcn-label-wrapper">
                            <label htmlFor="password">Password</label>
                          </div>
                          <div className="aplc-input-wrapper">
                            <input
                              type="password"
                              placeholder="Password"
                              required="required"
                              className=""
                              data-validation="password"
                              id="password"
                              name="_register.password"
                              value={this.state._register.password}
                              onChange={this.setValue.bind(this)}
                              onBlur={this.blur.bind(this)}
                              title="Password"
                            />
                          </div>
                        </div>
                        <div className="aplcn-form-row">
                          <div className="aplcn-label-wrapper">
                            <label htmlFor="confirmPassword">
                              Confirm Password
                            </label>
                            {this.state.confirmPassword &&
                              this.state.password !==
                              this.state.confirmPassword && (
                                <div className="aplcn-error-field">
                                  <i
                                    className="fa fa-info-circle aplcn-info-icon"
                                    aria-hidden="true"
                                  />{" "}
                                  Your password and confirmation password do not
                                  match.
                                </div>
                              )}
                          </div>
                          <div className="aplc-input-wrapper">
                            <input
                              type="password"
                              placeholder="Confirm Password"
                              required="required"
                              id="confirmPassword"
                              name="_register.password_confirmation"
                              value={this.state._register.password_confirmation}
                              onChange={this.setValue.bind(this)}
                              onBlur={this.blur.bind(this)}
                              data-validation="password"
                              title="Confirm password"
                            />
                          </div>
                        </div>

                        <div className="aplcn-form-row">
                          <div className="aplc-input-wrapper">
                            <input type="submit" value="Register" />
                          </div>

                          {this.state.loginProgress && (
                            <div className="loginProgress">
                              {" "}
                              <img
                                width="30"
                                src={require("../../images/loader.gif").default}
                              />
                            </div>
                          )}
                        </div>
                        <div className="aplcn-form-row">
                          {/* The Param passed is found in this.props.location.type.*/}
                          {/* <Link to={{ pathname: '/forgot_password', type: 'applicants' }}> */}
                          <a href="/forgot_password">
                            <p className="text-center link-green">Forgot Password</p>
                          </a>
                          {/* </Link> */}
                          {/* <div className="aplcn-social-media-auth">
                            <SocialButton
                              provider="facebook"
                              appId={config.facebookAppId}
                              onLoginSuccess={this.handleSocialLogin.bind(this)}
                              onLoginFailure={this.handleSocialLogin.bind(this)}
                              key={"facebook"}
                            >
                              <i
                                className="fa fa-facebook"
                                aria-hidden="true"
                              />
                            </SocialButton>

                            <SocialButton
                              provider="google"
                              appId={config.googleAppId}
                              onLoginSuccess={this.handleSocialLogin.bind(this)}
                              onLoginFailure={this.handleSocialLogin.bind(this)}
                              key={"google"}
                            >
                              <i
                                className="fa fa-google-plus"
                                aria-hidden="true"
                              />
                            </SocialButton>
                          </div> */}
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="aplcn-tabs-tab" data-tab-id="1">
                    <div className="aplcn-tab-form-wrapper">
                      <form
                        id="login"
                        action=""
                        onSubmit={this.login.bind(this, "login")}
                      >
                        <div className="aplcn-form-row">
                          <div className="aplcn-label-wrapper">
                            <label htmlFor="">Email ID</label>
                          </div>
                          <div className="aplc-input-wrapper">
                            <input
                              spellCheck="false"
                              type="email"
                              name="_login.email"
                              id="loginEmail"
                              placeholder="Email ID"
                              required="required"
                              value={this.state._login.email}
                              onChange={this.setValue.bind(this)}
                              onBlur={this.blur.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="aplcn-form-row">
                          <div className="aplcn-label-wrapper">
                            <label htmlFor="">Password</label>
                          </div>
                          <div className="aplc-input-wrapper">
                            <input
                              spellCheck="false"
                              type="password"
                              name="_login.password"
                              id="loginPassword"
                              placeholder="Password"
                              required="required"
                              data-validation="password"
                              value={this.state._login.password}
                              onChange={this.setValue.bind(this)}
                              onBlur={this.blur.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="aplcn-form-row">
                          <div className="aplc-input-wrapper">
                            <input type="submit" value="Log In" />
                          </div>

                          {this.state.loginProgress && (
                            <div className="loginProgress">
                              {" "}
                              <img
                                width="30"
                                src={require("../../images/loader.gif").default}
                              />
                            </div>
                          )}
                        </div>
                        <div className="aplcn-form-row">
                          {/* <Link to={{ pathname: '/forgot_password', type: 'applicants' }}> */}
                          <a href="/forgot_password">
                            <p className="text-center link-green">Forgot Password</p>
                          </a>
                          {/* </Link> */}
                          {/* <div className="aplcn-social-media-auth"> */}
                          {/* <SocialButton
                              provider="facebook"
                              appId={config.facebookAppId}
                              onLoginSuccess={this.handleSocialLogin.bind(this)}
                              onLoginFailure={this.handleSocialLogin.bind(this)}
                              key={"facebook"}
                            >
                              <i
                                className="fa fa-facebook"
                                aria-hidden="true"
                              />
                            </SocialButton>

                            <SocialButton
                              provider="google"
                              appId={config.googleAppId}
                              onLoginSuccess={this.handleSocialLogin.bind(this)}
                              onLoginFailure={this.handleSocialLogin.bind(this)}
                              key={"google"}
                            >
                              <i
                                className="fa fa-google-plus"
                                aria-hidden="true"
                              />
                            </SocialButton> */}
                          {/* </div> */}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return state;
}
const connectedApp = connect(mapStateToProps)(Login);
export { connectedApp as Login };
