import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import user from "./reducers/userReducer";
import api from "./reducers/apiReducer";
import alert from "./reducers/alertReducer";
import thunk from "redux-thunk";
import logger from "redux-logger";

let middleware = [thunk];
if (process.env.NODE_ENV !== "production") {
  // const thunk = require("redux-thunk");
  // const logger = require("redux-logger");
  // middleware = [...middleware, thunk , logger ];
  middleware.push(logger);
}
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  combineReducers({
    user: user,
    api: api,
    alert: alert,
  }),
  {},
  composeEnhancer(applyMiddleware(...middleware))
);
